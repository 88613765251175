function msieversion() {
  var ua = window.navigator.userAgent;
  var msie = ua.indexOf("MSIE ");

  // If Internet Explorer, return version number
  if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
    alert("Internet Explorer is no longer supported for security reasons. Please use Mozilla Firefox, Google Chrome, Opera, Microsoft Edge or Apple Safari.");
  }

  return false;
}
msieversion();